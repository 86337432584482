<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">
          {{title}}
        </h3>
      </div>
    </div>

    <hr class="my-3">

    <sgv-table
      :rKey="rKey"
      :items="devices"
      :headers="headers"
      :filter.sync="filter"
      :options.sync="options">

      <template slot-scope="{item, hidden}">
        <tr class="">
          <td
            v-if="hidden.code"
            @click="toDetail(item.id)"
            class="pointer"
            :class="{'text-danger': !item.isActive}">
            {{ item.code }}
          </td>
          <td v-if="hidden.name">{{ item.name }}</td>
          <td v-if="hidden.isOnline">
            <span v-if="item.isOnline" class="text-success">Online</span>
            <span v-else class="text-danger">Offline</span>
          </td>
        </tr>
      </template>

      <button
        v-if="$auth.hasRole(`device:${deviceType}:add`)"
        type="button"
        class="btn btn-link text-success"
        slot="action" @click="toDetail(0)">
        เพิ่ม
      </button>

    </sgv-table>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { LIST_DEVICE } from './graph/device'

export default {
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: `${this.title}-รายการ`
    }
  },
  props: {
    deviceType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      detailView: `Device${this.$form.capitalize(this.deviceType)}Detail`,
      rKey: `Device${this.$form.capitalize(this.deviceType)}List`,
      headers: [
        {text: 'รหัส', value: 'code', sort: true, filter: true},
        {text: 'ชื่อ', value: 'name', sort: true, filter: true},
        {text: 'สถานะ', value: 'isOnline'}
      ],
      filter: {
        limit: 10,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['code', 'name', 'isOnline'],
        column: null,
        search: null,
        toolbar: null,
      },
      devices: []
    }
  },
  apollo: {
    devices: {
      query () {
        return LIST_DEVICE(this.templateType)
      },
      variables() {
        this.setRetaining()
        return {
          deviceType: this.deviceType,
          q: this.getFilter(this.filter)
        }
      },
      debounce: 150,
      fetchPolicy: 'network-only',
    }
  },
  methods: {
    getFilter (v) {
      return {
        ...v,
        params: v.params || {}
      }
    },
    toDetail (id) {
      this.$router.push({
        name: this.detailView,
        params: {deviceId: id}
      })
    }
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  }
}
</script>

<style lang="css" scoped>
</style>
